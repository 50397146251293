<template>
  <div class="card shadow mb-1 mt-5 bg-white rounded">
    <div class="card-body cd1">
      <!-- <div class="alert alert-info" role="alert">欲查詢更完整價格歷程，可利用 <router-link to="/AdvSearch">進階查詢</router-link>，如要查詢逐月相關統計數據，可多加利用 <router-link to="/Stat">查統計</router-link></div>
   -->
      <div class="alert alert-light" role="alert">
        <p class="m-1 fs-4">{{ mainTitle }} <small class="fs-6" v-html="subTitle"></small></p>
        <div class="alert alert-info" role="alert">該功能提供各航線於該月各日價格視覺化呈現方式，欲進一步了解該日該班次票價以外資訊，可多加利用 <router-link to="/">首頁查詢</router-link> 或相關 <router-link to="/Lowfare?ref=IT-S25JPLOW">懶人包資料</router-link> 整理</div>
      </div>
      <form ref="form" class="row row-cols-lg-1" @submit.prevent="submit">
        <div class="row justify-content-md-center">
          <div class="col col-lg-4 col-12 mt-2">
            <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">請選擇</small></label>
            <div class="input-group">
              <div class="input-group-text">
                <i class="bi bi-arrow-left-right"></i>
              </div>
              <select class="form-control form-select input-height" id="select1" v-model="form.selectedItems1" required @change="selectItem($event)">
                <!-- <option value="" disabled></option> -->
                <option value="OW">單程</option>
                <option value="RT">來回</option>
                <option value="MULTI">不同點</option>
              </select>
            </div>
          </div>
          <div class="col col-lg-4 col-12 mt-2">
            <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">出發地</small></label>
            <div class="input-group">
              <template v-if="showMultiPage">
                <div class="input-group-text">
                  <label>第一段</label>
                </div>
              </template>
              <div class="input-group-text">
                <font-awesome-icon icon="plane-departure" />
              </div>
              <select class="form-control form-select input-height" name="" v-model="countryName1" @change="selectCity1" required>
                <option :value="item" v-for="(item, index) in area4IT_JPKR" :key="index" :selected="selectedDay === item.country">
                  {{ item.country }}
                </option>
              </select>
            </div>
          </div>
          <div class="col col-lg-4 col-12 mt-2">
            <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">目的地</small></label>
            <div class="input-group">
              <template v-if="showMultiPage">
                <div class="input-group-text">
                  <label>第一段</label>
                </div>
              </template>
              <div class="input-group-text">
                <font-awesome-icon icon="plane-arrival" />
              </div>
              <select class="form-control form-select input-height" name="" v-model="cityName1" @change="setCountryCity1" required>
                <option :value="item" v-for="(item, index) in countryName1.city" :key="index">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <template v-if="showMultiPage">
            <div class="row">
              <div class="col col-lg-4 col-12 mt-2"><div id="staticEmail3" class="form-text">選擇「不同點」時，本站不會去檢查搜尋條件是否符合航空公司搜尋規定</div></div>
              <div class="col col-lg-4 col-12 mt-2">
                <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">出發地</small></label>
                <div class="input-group">
                  <div class="input-group-text">
                    <label>第二段</label>
                  </div>
                  <div class="input-group-text">
                    <font-awesome-icon icon="plane-departure" />
                  </div>
                  <select class="form-control form-select input-height" name="" v-model="countryName2" @change="selectCity2" required>
                    <option :value="item" v-for="(item, index) in area4IT_JPKR" :key="index" :selected="selectedDay === item.country">
                      {{ item.country }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col col-lg-4 col-12 mt-2">
                <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">目的地</small></label>
                <div class="input-group">
                  <div class="input-group-text">
                    <label>第二段</label>
                  </div>
                  <div class="input-group-text">
                    <font-awesome-icon icon="plane-arrival" />
                  </div>
                  <select class="form-control form-select input-height" name="" v-model="cityName2" @change="setCountryCity2" required>
                    <option :value="item" v-for="(item, index) in countryName2.city" :key="index">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row justify-content-md-center">
          <div class="col col-lg-4 col-12 mt-2">
            <fieldset class="row mb-3">
              <legend class="col-form-label pt-0">
                <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">查詢條件1</small></label>
              </legend>
              <div class="row">
                <div class="col-auto">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gridRadios1" id="gridRadios1" value="1" v-model="form.picked1" @change="changeItem1($event)" />
                    <label class="form-check-label" for="gridRadios1"> 指定範圍（夏冬季） </label>
                    <select class="form-control form-select form-select-lg input-height form-control-lg" id="select1" v-model="form.dateRange2" :disabled="!disabled1" required>
                      <option value="" disabled>=== 請選擇 ===</option>
                      <option value="S25">25夏 (2025/03/30 ~ 2025/10/25)</option>
                      <!-- <option value="W25">25冬</option> -->
                    </select>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="gridRadios1" id="gridRadios2" value="2" v-model="form.picked1" @change="changeItem1($event)" />
                    <label class="form-check-label" for="gridRadios2"> 指定月份 </label>
                    <!-- <label class="form-check-label" for="gridRadios2"> Second radio </label> -->
                    <select class="form-control form-select form-select-lg input-height form-control-lg" id="select1" v-model="form.dateRange2" :disabled="disabled1" required>
                      <option value="" disabled>=== 25夏 (2025/03/30 ~ 2025/10/25) ===</option>
                      <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>
                    </select>
                  </div>
                </div>
                <!-- <div class="col"></div> -->
              </div>
            </fieldset>
          </div>
          <div class="col col-lg-4 col-12 mt-2">
            <fieldset class="row mb-3">
              <legend class="col-form-label pt-0">
                <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">查詢條件2</small></label>
                <div id="staticEmail2" class="form-text">指定班次只適用於單程查詢時，航班資訊可參考<a href="https://www.tigerairtw.com/zh-TW/ready-to-go/flight-schedule" target="_blank">官網時刻表</a></div>
              </legend>
              <div class="row">
                <div class="col-auto">
                  <div class="form-check mt-2">
                    <input class="form-check-input" type="radio" name="gridRadios2" id="gridRadios3" value="1" v-model="form.picked2" @change="changeItem2($event)" />
                    <label class="form-check-label" for="gridRadios3"> 不指定班次 </label>
                  </div>
                </div>
                <template v-if="!showFlightSelect">
                  <div class="col-auto">
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" name="gridRadios2" id="gridRadios4" value="2" v-model="form.picked2" @change="changeItem2($event)" />
                      <label class="form-check-label" for="gridRadios4"> 指定班次 </label>
                      <div class="input-group mb-3">
                        <span class="input-group-text fs-4" id="basic-addon1">IT</span>
                        <input type="text" class="form-control form-control-lg" id="gridRadios2" placeholder="請輸入編號(如200)" v-model="form.flightNo" required :disabled="disabled2" />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </fieldset>
          </div>
          <div class="col col-lg-2 col-12 mt-2">
            <legend class="col-form-label pt-0">
              <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">備註</small></label>
              <div id="staticEmail2" class="form-text">暫時無法查詢「台北＝小松」、「台北＝花卷」</div>
            </legend>
          </div>
          <div class="col col-lg-2 col-12">
            <button class="btn btn-primary btn-lg float-end mt-5" type="submit">查日曆</button>
          </div>
        </div>

        <!-- <div class="col-12"></div> -->
      </form>
    </div>
  </div>
</template>
<style>
  .input-height {
    height: 39px;
  }
  .cd1 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url("../assets/1971537.jpg");
    border-radius: 50px;
  }
</style>

<script>
  // import SearchFromV2 from './SearchFrom_v2.vue';
  //   import SearchFromV3 from "./SearchFrom_v3.vue"
  // import { mapState } from "pinia"
  import { usePromoStore } from "@/store/main.js"
  // import { storeToRefs } from "pinia"

  import moment from "moment"
  import iataCode from "@/mixins/iataCode.js"
  import axios from "axios"
  export default {
    name: "VueChart",
    components: {},
    mixins: [iataCode],
    data() {
      return {
        // id: null,
        // area4IT_JPKR: [],
        months: ["2025-04", "2025-05", "2025-06", "2025-07", "2025-08", "2025-09", "2025-10"],
        mainTitle: "票價日曆",
        subTitle: '<small class="fs-6">目前僅提供台灣虎航日本、韓國航線為主查詢</small>',
        disabled1: false,
        disabled2: true,
        showMultiPage: false,
        showFlightSelect: false,
        countryName1: {},
        cityName1: "",
        countryName2: {},
        cityName2: "",
        selectedDay: "台北(桃園)|TPE",
        // city: [],
        // inputContent: 'rrr',
        form: {
          countryName1: [],
          cityName1: [],
          countryName2: [],
          cityName2: [],
          selectedItems1: "OW",
          // selectedItems2: [],
          // selectedItems3: [],
          dateRange: {},
          dateRange2: "2025-04",
          picked1: "2",
          picked2: "1",
          flightNo: "",
        },
      }
    },
    mounted: function () {
      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        const regex = /SALE|LOW/gi
        const found = ref.match(regex)
        if (found !== null) {
          const getPromoInfo = usePromoStore()
          getPromoInfo.isPromo = true

          // this.id = true
          this.getPromoInfo(ref)
        }
      }
    },
    // computed: {
    //   ...mapState(usePromoStore, ["totalCost"]),
    // },
    methods: {
      generateMonthsRange(startDate, endDate) {
        const fromDate = moment(startDate, "YYYY-MM-DD")
        const toDate = moment(endDate, "YYYY-MM-DD")
        let monthData = []

        while (toDate > fromDate || fromDate.format("M") === toDate.format("M")) {
          monthData.push(fromDate.format("YYYY-MM"))
          fromDate.add(1, "month")
        }
        return monthData
      },
      async getPromoInfo(index) {
        const me = this

        try {
          const response = await axios.get("./it-sale.json")
          let jsonData = response.data

          for (let i = 0; i < jsonData.length; i++) {
            if (jsonData[i]["ref"] == index) {
              me.mainTitle = jsonData[i].mainTitle
              me.subTitle = jsonData[i].subTitle
              me.area4IT_JPKR = jsonData[i].content["route_list"]

              const start_m = jsonData[i].content.start_m
              const end_m = jsonData[i].content.end_m
              me.months = me.generateMonthsRange(start_m, end_m)
              me.form.dateRange2 = me.months[0]

              const open_d = jsonData[i].open_d
              const close_d = jsonData[i].close_d
              const getPromoInfo = usePromoStore()
              getPromoInfo.promoInfo = jsonData[i]

              if (!moment().tz("Asia/Taipei").isBetween(open_d, close_d)) {
                me.$alert(`活動尚未開始或者已經結束`).then(() => {
                  window.location.href = `./FareCal`
                })
              }
            }
          }
        } catch (error) {
          me.$alert(`參數讀取錯誤`).then(() => {
            window.location.href = `./FareCal`
          })
          console.error("Error loading JSON data:", error)
        }
      },
      changeItem1(event) {
        if (event.target.value === "1") {
          this.disabled1 = true
        } else {
          this.disabled1 = false
        }
      },
      changeItem2(event) {
        if (event.target.value === "1") {
          this.disabled2 = true
        } else {
          this.disabled2 = false
        }
      },
      selectItem(event) {
        if (event.target.value === "MULTI") {
          this.showMultiPage = true
        } else {
          this.showMultiPage = false
        }

        if (event.target.value === "OW") {
          this.showFlightSelect = false
        } else {
          this.showFlightSelect = true
        }
      },
      getD(val) {
        this.form.dateRange = val
      },
      getD2(val) {
        this.form.dateRange2 = val
      },
      submit() {
        const formData = this.form
        this.$emit("query", formData)
      },
      selectCity1(value) {
        const selectedIndex = value.target.options.selectedIndex
        this.form.countryName1 = this.area4IT_JPKR[selectedIndex].country
      },
      setCountryCity1(value) {
        this.form.cityName1 = ""
        const selectedIndex = value.target.options.selectedIndex
        const countryName = this.form.countryName1
        const area = this.area4IT_JPKR
        area.forEach((e) => {
          if (e.country === countryName) {
            this.form.cityName1 = e.city[selectedIndex]
          }
        })
      },
      selectCity2(value) {
        const selectedIndex = value.target.options.selectedIndex
        this.form.countryName2 = this.area4IT_JPKR[selectedIndex].country
      },
      setCountryCity2(value) {
        this.form.cityName2 = ""
        const selectedIndex = value.target.options.selectedIndex
        const countryName = this.form.countryName2
        const area = this.area4IT_JPKR
        area.forEach((e) => {
          if (e.country === countryName) {
            this.form.cityName2 = e.city[selectedIndex]
          }
        })
      },
    },
  }
</script>

<style></style>
