import { defineStore } from "pinia"

// 這邊 defineStore 會自動動態註冊模組，回傳值為 hook function
export const usePromoStore = defineStore("Main", {
  // 注意 state 是一個 function，推薦使用 arrow function
  // 可幫助 typescript 更好進行類型推斷
  state: () => ({
    id: null,
    isPromo: false,
    promoInfo: null,
  }),
  getters: {
    totalCost(state) {
      return state.promoInfo
    },
  },
  actions: {},
})
